import React from 'react';
import rocket from './rocket.png';
import './Loading.css';

function Loading() {
  return (
    <>
      <img src={rocket} className="loading-png" alt="rocket" />
    </>
  );
}

export default Loading;
