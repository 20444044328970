import React from 'react';
import logo from './logo.svg';
import './App.css';

import Loading from './components/Loading';

function App() {
  return (
    <>
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
        </div>
      </section>
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <Loading />
            <h1 className="title">Launching Soon</h1>
            <h2 className="subtitle">
              We're crafting something big, stay tune!
            </h2>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
